import store from '@/store'

export function displayText (key, defaultText) {
  if (! store.state.general.dashboardModule) return defaultText;

  let textMapping = store.state.general.dashboardModule.text_mapping ?? [];

  const keys = key.split('.');
  textMapping = textMapping[keys[0]] ?? [];

  if (!Array.isArray(textMapping)) return defaultText;

  const text = textMapping.find(mapping => mapping.key === keys[1]);

  return text ? text.value : defaultText;
}

export function getSampleExcelFile (key, defaultUrl) {
  const module = store.state.dashboardModule.modules.find(module => module.module_code === 'file')?.module_config
    if (! module) {
      window.location.href = `${window.location.origin}${defaultUrl}`
      return
    }

  let sampleFile = module.sample_file ?? [];
  const url = sampleFile[key]?.url ?? false;
  if (!url) {
    console.log(`${window.location.origin}${defaultUrl}`)
    window.location.href = `${window.location.origin}${defaultUrl}`
    return
  }
  window.location.href = url
}
